@import "common/styles/core/_import.p.css";
@import "common/styles/assets/Debug/_import.p.css";

/*
	project/htdocs/common 以下の共通CSSファイルを
	HTML側で読み込んでいる場合は内容が重複するのでコメントアウトしておく
	@import "common/styles/layout/_import.pcss";
	@import "common/styles/module/_import.pcss";
	@import "common/styles/sanitize/_import.pcss";
	@import "common/styles/state/_import.pcss";
*/

/* 開発用のスタイル */
/*
#js-st-Lording {
  visibility: hidden;
}
*/

.$(prefix)-Body {

  /* ============================================================================= */
  /*  .tp-About                                                                    */
  /* ============================================================================= */
  .tp-About_Outer {
    position: relative;
    margin: 0 0 200px;

    &.js-InView {
      opacity: 0.0;
      .dbg & { opacity: 1.0; }
    }

    @media (--only-md-screen) {
      margin: 0 0 120px;
    }

    @media (--only-sm-screen-less) {
      margin: 0 0 60px;
    }

    &::before {
      /* ディレクトリを変える必要がある */
      background-image: url('https://imgsrc-corporate.s3-ap-northeast-1.amazonaws.com/wp-content/uploads/about-pc-0.png');
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 100%;
      padding: calc(1080 / 1920 * 100%) 0 0;
      position: relative;

      @media (--only-md-screen-less) {
        background-image: url('https://imgsrc-corporate.s3-ap-northeast-1.amazonaws.com/wp-content/uploads/about-sp-1.png');
        padding: calc(1286 / 1536 * 100%) 0 0;
      }

      @media (--only-md-screen) {
        margin: 0 0 120px;
      }

      @media (--only-sm-screen-less) {
        margin: 0 0 60px;
      }
    }

    /* .sw-Container {} */

      .sw-Container_Inner {
        @media (--only-md-screen-over) {
          padding-right: 0;
          padding-left: 0;
        }
      }
  }

    /* .tp-About {} */

      /* .tp-About_Inner {} */

        /* .tp-About_Section {} */

          .tp-About_Content {
            position: relative;
          }

            $tpAboutParasPaddingRight: 113;

            .tp-About_Paras {
              text-align: right;
              width: 100%;
              padding: 0 calc($tpAboutParasPaddingRight / 1366 * 100%) calc(61 / 1366 * 100%) 0;
              position: absolute;
              bottom: 0;

              @media (--only-md-screen-less) {
                position: relative;
                padding: 0;
              }
            }

              .tp-About_Para {
                $fontSize: 32;

                display: inline-block;
                font-family: Georgia, $fontFamilySet3;
                font-feature-settings: "palt" 1;
                font-size: $(fontSize)px;
                font-size: calc($fontSize / 1366 * 100vw);
                /* font-size: clamp(calc($fontSize * (($mediumScreenWidth + 1) / 1366) / 1366 * 100vw), calc($fontSize / 1366 * 100vw), $(fontSize)px); */
                font-style: italic;
                font-kerning: auto;
                font-weight: 400;
                text-align: left;
                letter-spacing: 0.002em;
                line-height: 1.62;
                width: calc(720 / (1366 - $tpAboutParasPaddingRight) * 100%);
                margin: 0;

                @media (--only-md-screen-less) {
                  display: block;
                  width: 100%;
                }

                @media (--only-md-screen) {
                  $fontSize: 32;

                  font-size: $(fontSize)px;
                  font-size: calc($fontSize / $mediumScreenWidth * 100vw);
                }

                @media (--only-sm-screen-less) {
                  $fontSize: 14;

                  font-size: $(fontSize)px;
                  font-size: calc($fontSize / $remBase)rem;
                }
              }

                .tp-About_Link {
                  border-bottom: #000 solid 1px;
                  color: #333;
                  text-decoration: none;
                  transition-property: opacity;
                  transition-duration: 0.2s;
                  transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);

                  &:hover {
                    opacity: 0.5;
                  }
                }

  /* ============================================================================= */
  /*  End .tp-About                                                                */
  /* ============================================================================= */



  /* ============================================================================= */
  /*  .lib                                                                         */
  /* ============================================================================= */
  .lib-Outer {
    &.js-InView {
      opacity: 0.0;
      .dbg & { opacity: 1.0; }
    }
  }

  /* &.lib-Outer */

  .lib-work,
  .lib-installation {
    margin: 0 0 80px;

    @media (--only-md-screen) {
      margin: 0 0 60px;
    }

    @media (--only-sm-screen-less) {
      margin: 0 0 40px;
    }
  }

  .lib-lab {
    margin: 0 0 160px;

    @media (--only-md-screen) {
      margin: 0 0 100px;
    }

    @media (--only-sm-screen-less) {
      margin: 0 0 60px;
    }
  }

  /* End &.lib-Outer */

    /* .lib-Section {} */

      .lib-Container {
        margin: 0 0 40px;

        @media (--only-md-screen) {
          margin: 0 0 20px;
        }

        @media (--only-sm-screen-less) {
          margin: 0 0 24px;
        }
      }

        /* .lib {} */

          /* .lib-Inner {} */

            .lib-Header {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

              .lib-Heading {
                $fontSize: 32;

                font-family: Montserrat, $fontFamilySet1;
                font-feature-settings: 'palt' 1;
                font-size: $(fontSize)px;
                font-size: calc($fontSize / $remBase)rem;
                text-transform: uppercase;
                font-kerning: auto;
                font-weight: 400;
                letter-spacing: 0.02em;
                line-height: 1.6;
                margin: 0;

                @media (--only-sm-screen-less) {
                  $fontSize: 14;

                  font-size: $(fontSize)px;
                  font-size: calc($fontSize / $remBase)rem;
                }
              }

              .lib-More {}

                .lib-MoreButton {
                  cursor: pointer;
                  display: block;
                  background-color: #fff;
                  border-radius: 0;
                  border: solid 1px #999;
                  color: #333;
                  text-align: center;
                  text-decoration: none;
                  touch-action: manipulation;
                  white-space: nowrap;
                  user-select: none;
                  width: 168px;
                  padding: 9px 0;
                  position: relative;
                  overflow: hidden;
                  transition-property: background-color, color;
                  transition-duration: 0.2s;
                  transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);

                  &:hover {
                    color: #fff;
                    background-color: #999;
                  }

                  @media (--only-sm-screen-less) {
                    width: 136px;
                    padding: 7px 0;
                  }
                }

                  .lib-MoreButton_Label {
                    $fontSize: 16;

                    font-family: 'Montserrat', $fontFamilySet3;
                    font-size: $(fontSize)px;
                    font-size: calc($fontSize / $remBase)rem;
                    font-weight: 400;
                    letter-spacing: 0.15em;
                    line-height: 1.4;
                    text-transform: uppercase;

                    @media (--only-sm-screen) {
                      $fontSize: 12;

                      font-size: $(fontSize)px;
                      font-size: calc($fontSize / $remBase)rem;
                      letter-spacing: 0.1em;
                    }
                  }

              /* End .lib-More */

      /* .lib-Content {} */

        $listColumn: 3;
        $mdCaptionColumn: 2;
        $mdCaptionBasis: calc($mediumScreenWidth / $mdCaptionColumn);
        $smCaptionColumn: 2;
        $smCaptionBasis: calc($smallDesignWidth / $smCaptionColumn);

        .lib-List {
          font-size: 0;
          padding: 0;
        }

          .lib-Item {
            display: inline-block;
            width: calc(100% / $listColumn);
            overflow: hidden;

            @media (--only-sm-screen-less) {
              width: 50%;
            }

            &:last-child {
              @media (--only-sm-screen-less) {
                display: none;
              }
            }
          }

            /* .lib-Item_Inner {} */

              .lib-Item_Link {
                display: block;
                text-decoration: none;
                color: $typographyColor3;
                position: relative;

                &:hover,
                &:active {
                  .lib-Caption { opacity: 1.0; }
                }
              }

                .lib-Caption {
                  background-color: rgba(0, 0, 0, 0.8);
                  width: 100%;
                  height: 100%;
                  padding: 36px 40px;
                  position: absolute;
                  z-index: 2;
                  opacity: 0.0;
                  transition-property: opacity;
                  transition-duration: 0.4s;
                  transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);
                  @media (--only-md-screen) {
                    padding: calc(30 / $mdCaptionBasis * 100%) calc(30 / $mdCaptionBasis * 100%);
                  }
                  @media (--only-sm-screen) {
                    padding: calc(14 / $smCaptionBasis * 100%) calc(15 / $smCaptionBasis * 100%);
                  }
                }

                  .lib-Caption_Heading {
                    $fontSize: 20;

                    font-family: 'Montserrat', $fontFamilySet1;
                    font-feature-settings: 'palt' 1;
                    font-size: calc($fontSize / $remBase)rem;
                    font-kerning: auto;
                    font-weight: 400;
                    letter-spacing: 0.02em;
                    line-height: 1.5;
                    padding: 0;
                    margin: 0;

                    @media (--only-md-screen) {
                      $fontSize: 16;

                      font-size: calc($fontSize / $remBase)rem;
                    }

                    @media (--only-sm-screen) {
                      $fontSize: 12;

                      font-size: calc($fontSize / $remBase)rem;
                    }
                  }

              .lib-ImgContainer {
                position: relative;
                padding: calc(9 / 16 * 100%) 0 0;
                height: auto;
                max-width: 100%;
                overflow: hidden;
                z-index: 1;
              }

                .lib-ImgContainerInner {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }

                  .lib-Img {
                    line-height: 0;
                    width: 101%;
                    max-width: 101%;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }

  /* ============================================================================= */
  /*  End .lib                                                                     */
  /* ============================================================================= */



  /* ============================================================================= */
  /*  .blog                                                                        */
  /* ============================================================================= */
  .blog-Outer {
    margin: 0 0 160px;

    @media (--only-md-screen) {
      margin: 0 0 100px;
    }

    @media (--only-sm-screen-less) {
      margin: 0 0 60px;
    }

    &.js-InView {
      opacity: 0.0;
      .dbg & { opacity: 1.0; }
    }
  }

    /* .blog {} */

      /* .blog-Inner {} */

        /* .blog-Section {} */

          .blog-Header {
            margin: 0 0 45px;

            @media (--only-md-screen-less) {
              margin: 0 0 20px;
            }
          }

            .blog-Heading {
              $fontSize: 32;

              font-family: Montserrat, $fontFamilySet1;
              font-feature-settings: 'palt' 1;
              font-size: $(fontSize)px;
              font-size: calc($fontSize / $remBase)rem;
              text-transform: uppercase;
              font-kerning: auto;
              font-weight: 400;
              letter-spacing: 0.02em;
              line-height: 1.6;
              margin: 0;

              @media (--only-sm-screen-less) {
                $fontSize: 14;

                font-size: $(fontSize)px;
                font-size: calc($fontSize / $remBase)rem;
              }
            }

          /* End .blog-Header */

          /* .blog-Content {} */

            .blog-List {
              list-style: none;
              padding: 0;
              margin: 0;
            }

              .blog-Item {
                margin: 0 0 40px;

                &:last-child {
                  margin: 0;
                }

                @media (--only-md-screen) {
                  margin: 0 0 30px;
                }

                @media (--only-sm-screen-less) {
                  margin: 0 0 20px;
                }
              }

                .blog-Item_Link {
                  $fontSize: 16;

                  display: flex;
                  color: #333;
                  font-family: 'Montserrat', $fontFamilySet1;
                  font-feature-settings: 'palt' 1;
                  font-size: $(fontSize)px;
                  font-size: calc($fontSize / $remBase)rem;
                  text-decoration: none;
                  letter-spacing: 0.02em;
                  line-height: 1.6;
                  transition-property: opacity;
                  transition-duration: 0.2s;
                  transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);

                  &:hover,
                  &:active {
                    opacity: 0.5;
                  }

                  @media (--only-sm-screen-less) {
                    $fontSize: 12;

                    display: block;
                    font-size: $(fontSize)px;
                    font-size: calc($fontSize / $remBase)rem;
                  }
                }

                  .blog-Item_Time {
                    display: block;
                    flex-basis: 166px;
                    flex-shrink: 0;

                    @media (--only-sm-screen-less) {
                      margin: 0 0 4px;
                    }
                  }

                  .blog-Item_Par {
                    flex-grow: 1;
                    margin: 0;
                  }
  /* ============================================================================= */
  /*  End .blog                                                                    */
  /* ============================================================================= */



  /* ============================================================================= */
  /*  .blog                                                                        */
  /* ============================================================================= */
  .contact-Outer {
    margin: 0 0 180px;

    @media (--only-md-screen) {
      margin: 0 0 120px;
    }

    @media (--only-sm-screen-less) {
      margin: 0 0 80px;
    }

    &.js-InView {
      opacity: 0.0;
      .dbg & { opacity: 1.0; }
    }
  }

    /* .contact {} */

      /* .contact-Inner {} */

        /* .contact-Section {} */

          .contact-Header {
            margin: 0 0 45px;

            @media (--only-md-screen-less) {
              margin: 0 0 20px;
            }
          }

            .contact-Heading {
              $fontSize: 32;

              font-family: Montserrat, $fontFamilySet1;
              font-feature-settings: 'palt' 1;
              font-size: $(fontSize)px;
              font-size: calc($fontSize / $remBase)rem;
              text-transform: uppercase;
              font-kerning: auto;
              font-weight: 400;
              letter-spacing: 0.02em;
              line-height: 1.6;
              margin: 0;

              @media (--only-sm-screen) {
                $fontSize: 14;

                font-size: $(fontSize)px;
                font-size: calc($fontSize / $remBase)rem;
              }
            }

          /* .contact-Content {} */

            .contact-List {
              list-style: none;
              padding: 0;
              margin: 0;
            }

              .contact-Item {
                margin: 0 0 30px;

                @media (--only-sm-screen-less) {
                  margin: 0 0 18px;
                }
              }

                .contact-Link {
                  $fontSize: 16;

                  color: #333;
                  font-family: 'Montserrat', $fontFamilySet3;
                  font-size: $(fontSize)px;
                  font-size: calc($fontSize / $remBase)rem;
                  text-decoration: none;
                  letter-spacing: 0.02em;
                  line-height: 1.6;
                  transition-property: opacity;
                  transition-duration: 0.2s;
                  transition-timing-function: cubic-bezier(0.14, 1, 0.54, 0.92);

                  &:hover,
                  &:active {
                    opacity: 0.5;
                  }

                  @media (--only-sm-screen-less) {
                    $fontSize: 12;

                    font-size: $(fontSize)px;
                    font-size: calc($fontSize / $remBase)rem;
                  }
                }

                  .contact-Link_Icon {
                    @media (--only-sm-screen-less) {
                      $fontSize: 16;

                      font-size: $(fontSize)px;
                      font-size: calc($fontSize / $remBase)rem;
                    }
                  }
  /* ============================================================================= */
  /*  End .blog                                                                    */
  /* ============================================================================= */
}
