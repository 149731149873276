/*
 * postcss-simple-vars
 * https://github.com/postcss/postcss-simple-vars
 */
$prefix: ims;

/* Base Font size(px) */
$fontSize: 10;
$remBase: $fontSize;

/* Base line-height */
$lineHeight: ($fontSize / 15);

/* Base Window Size (px) */
$xLargeScreenWidth: 1600;
$xLargeScreenGutterWidth: 100;
$xLargeScreenInnerWidth: calc($xLargeScreenWidth - ($xLargeScreenGutterWidth * 2));

$largeScreenWidth: 992;
$largeScreenGutterWidth: 100;
$largeScreenInnerWidth: calc($largeScreenWidth - ($largeScreenGutterWidth * 2));

$mediumScreenWidth: 768;
$mediumScreenGutterWidth: 65;
$mediumScreenInnerWidth: calc($mediumScreenWidth - ($mediumScreenGutterWidth * 2));

$smallScreenWidth: 480;
$smallScreenGutterWidth: 30;
$smallScreenInnerWidth: calc($smallScreenWidth - ($smallScreenGutterWidth * 2));

$smallDesignWidth: 320;
$smallDesignGutterWidth: 30;
$smallDesignInnerWidth: calc($smallDesignWidth - ($smallDesignGutterWidth * 2));

$baseMaxWidth: 1500px;
$baseMaxHeight: 600px;
$baseMinWidth: 350px;
$baseMinHeight: 560px;

/* Font Settings */
$fontFamilySet0: "游ゴシック体", "Yu Gothic", "YuGothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", 'Meiryo', "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", -apple-system, BlinkMacSystemFont, sans-serif;
$fontFamilySet1: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", 'Meiryo', "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", -apple-system, BlinkMacSystemFont, Verdana, Roboto, "Droid Sans", sans-serif;
$fontFamilySet2: "Noto Sans Japanese", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", 'Meiryo', "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", -apple-system, BlinkMacSystemFont, Verdana, Roboto, "Droid Sans", sans-serif;
$fontFamilySet3: -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;

/* Colors */
$backgroundColor0: #ffffff;
$backgroundColor1: #000000;
$backgroundColor2: #333333;
$backgroundColor3: #c8c8c8;
$backgroundColor4: #eeeeee;
$typographyColor0: #333333;
$typographyColor1: #999999;
$typographyColor2: #c8c8c8;
$typographyColor3: #ffffff;
$borderColor0: #c8c8c8;
$borderColor1: #333333;
$borderColor2: #ffffff;

$colorBase:    #222222;
$colorBg:      #ffffff;
$colorLink:    #999999; /* :link color */
$colorHover:   #cccccc; /* :hover color */
$colorActive:  #cccccc; /* :active color */
$colorVisited: #aaaaaa; /* :visited color */
$colorFocus:   #cccccc; /* :focus color */


/* Break Point */
$mqSmall: 980px;
$mqMiddle: 768px;
$mqLarge: 1280px;

$supportMqExceed: true;
$supportMqLarge: true;
$supportMqMiddle: true;
$supportMqSmall: true;
