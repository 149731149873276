/*
 * cssnext#custom-media-queries
 * http://cssnext.io/features/#custom-media-queries
 */
@custom-media --only-sm-screen-less (width <= $(smallScreenWidth)px);
@custom-media --only-sm-screen (width <= $(smallScreenWidth)px);

@custom-media --only-md-screen-less (width <= $(mediumScreenWidth)px);
@custom-media --only-md-screen (width > $(smallScreenWidth)px) and (width <= $(mediumScreenWidth)px);
@custom-media --only-md-screen-over (width > $(mediumScreenWidth)px);

@custom-media --only-lg-screen-less (width <= $(largeScreenWidth)px);
@custom-media --only-lg-screen (width > $(mediumScreenWidth)px) and (width <= $(largeScreenWidth)px);

@custom-media --only-xlg-screen (width > $(largeScreenWidth)px) and (width <= $(xLargeScreenWidth)px);
@custom-media --only-xlg-screen-over (width > $(xLargeScreenWidth)px);
