.dbg-Border {
	box-shadow: rgb(0, 0, 0) 0px 0px 0px 1px inset;
	background-color: rgba(204, 204, 204, 0.25);
	& * {
		box-shadow: rgb(0, 0, 0) 0px 0px 0px 1px inset;
		background-color: rgba(204, 204, 204, 0.25);
	}

	&__is--none {
		box-shadow: none;
		background-color: transparent;
		& * {
			box-shadow: none;
			background-color: transparent;
		}
	}
}

.dbg-Overlay_Outer {
  position: relative;
}

.dbg-Overlay {
  background-position: center 0px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2147483647;
  opacity: 0.5;
  pointer-events: none;
  @media (--only-sm-screen) {
    background-size: 320px auto;
  }
}
